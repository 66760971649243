import * as styles from './trackStyles'
import React from 'react'
import { DayEntry } from 'modules/shared/DayEntry'
import { EntriesGroupedByDay } from 'types/timeEntry'

type EntriesListProp = {
  entriesGroup: EntriesGroupedByDay
}

export const EntriesList = React.memo(function EntriesList({
  entriesGroup
}: EntriesListProp) {
  return (
    <div className={styles.groups()}>
      {Object.entries(entriesGroup).map(([date, dayGroup]) => (
        <DayEntry key={date} dayGroup={dayGroup} showTime />
      ))}
    </div>
  )
})
