import Head from 'next/head'
import { authSelectors } from 'store/auth'
import { PageLoader } from 'components/Loading'
import { Track } from 'modules/Track'
import { useOdoSelector } from 'store'
import { useTrackHeadTitle } from 'hooks/useTrackHeadTitle'

function NextHead() {
  const title = useTrackHeadTitle()

  return (
    <Head>
      <title>{title}</title>
    </Head>
  )
}

export default function LogPage() {
  const isLoading = useOdoSelector(authSelectors.loading)

  if (isLoading) return <PageLoader />

  return (
    <>
      <NextHead />
      <Track />
    </>
  )
}
