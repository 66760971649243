import { logEntriesSelectors } from 'store/logEntries'
import { useOdoSelector } from 'store/store'

export const useRunningEntry = () => {
  const runningEntry = useOdoSelector(logEntriesSelectors.runningEntry)

  return {
    runningEntry
  }
}
