import { useSelector } from 'react-redux'
import { authSelectors } from 'store/auth'
import { spacesSelectors } from 'store/spaces'
import { TimeEntry } from 'types/timeEntry'
import { useDraftEntry } from './useDraftEntry'
import { update, updateDebounced, create } from 'services/actions/entryActions'
import { useRunningEntry } from './useRunningEntry'

export const useCurrentEntry = () => {
  const spaceId = useSelector(spacesSelectors.currentSpaceId)
  const uid = useSelector(authSelectors.uid)

  const { runningEntry } = useRunningEntry()
  const draft = useDraftEntry()

  const toggleTimer = () => {
    if (runningEntry) {
      // Stop the entry by adding and end date
      update(runningEntry.id, { dateEnd: new Date() })
    } else {
      // Create a new entry starting now
      create({ ...draft.draftEntry, spaceId, uid, dateStart: new Date() })
      draft.reset()
    }
  }

  const setDescription = ({ description, tags, nodes }) => {
    if (runningEntry) {
      updateDebounced(runningEntry.id, { description, tags, nodes })
    } else {
      draft.update({ description, tags, nodes })
    }
  }

  const setEntryDates = (startDate?: Date, endDate?: Date) => {
    if (!startDate && !endDate) return

    const changes: Partial<TimeEntry> = {}

    if (startDate) changes.dateStart = startDate
    if (endDate) changes.dateEnd = endDate

    if (runningEntry) {
      updateDebounced(runningEntry.id, changes)
    } else {
      draft.update(changes)
    }
  }

  const addManualEntry = (dateStart: Date, dateEnd: Date) => {
    create({ ...draft.draftEntry, spaceId, uid, dateStart, dateEnd })
    draft.reset()
  }

  return {
    currentEntry: runningEntry ?? draft.draftEntry,
    setDescription,
    toggleTimer,
    addManualEntry,
    setEntryDates
  }
}
