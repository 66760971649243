import * as actions from 'services/actions/entryActions'
import TimeEntry from 'modules/shared/TimeEntry'
import { Button } from '@sc/components/Button'
import { Div } from '@sc/components/Div'
import { Divider } from '@sc/components/Divider'
import { Fragment, useState } from 'react'
import { logEntriesSelectors } from 'store/logEntries'
import { Row, RowLeft, RowRight } from '@sc/components/Row'
import { TextGroup } from '@sc/components/TextGroup'
import { useOdoSelector } from 'store'
import { useTranslation } from 'react-i18next'

export const SampleEntriesGroup = () => {
  const { t } = useTranslation()

  const [removing, setRemoving] = useState(false)

  const sampleEntryIds = useOdoSelector(logEntriesSelectors.sampleLogEntryIds)

  const removeSampleEntries = async () => {
    setRemoving(true)
    await actions.removeSampleEntries()
    setRemoving(false)
  }

  if (sampleEntryIds.length === 0) return null

  return (
    <Div
      css={{
        marginBottom: '$6',
        borderRadius: '$M',
        overflow: 'hidden'
      }}
    >
      <Row
        css={{
          paddingX: '$paddingContainerLevelOne',
          backgroundColor: '$surfaceFg'
        }}
      >
        <RowLeft>
          <TextGroup.Root>
            <TextGroup.Primary>
              {t('track.sampleEntries.title')}
            </TextGroup.Primary>
            <TextGroup.Secondary>
              {t('track.sampleEntries.subtitle')}
            </TextGroup.Secondary>
          </TextGroup.Root>
        </RowLeft>
        <RowRight>
          <Button onClick={removeSampleEntries} disabled={removing}>
            {removing
              ? t('track.sampleEntries.removeButtonRemoving')
              : t('track.sampleEntries.removeButton')}
          </Button>
        </RowRight>
      </Row>

      {sampleEntryIds.map(id => (
        <Fragment key={id}>
          <Divider />
          <TimeEntry id={id} />
        </Fragment>
      ))}
    </Div>
  )
}
