import useSWR from 'swr'
import { authSelectors } from 'store/auth'
import { fetcher } from 'services/timeEntries'
import { spacesSelectors } from 'store/spaces'
import { useSelector } from 'react-redux'

export const getKey = (spaceId?: string, uid?: string) =>
  uid && spaceId ? [spaceId, uid, 'time_entries'] : null

export const useEntries = () => {
  const spaceId = useSelector(spacesSelectors.currentSpaceId)
  const uid = useSelector(authSelectors.uid)

  const { data } = useSWR(getKey(spaceId, uid), ([spaceId]) =>
    fetcher(spaceId, 'swr')
  )

  return {
    isLoading: data === undefined
  }
}
