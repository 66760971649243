import { css } from 'theme/stitches.config'

export const entriesContainer = css({
  paddingX: '$paddingContainerLevelOne',
  paddingBottom: '$bottomNavMargin'
})

export const groups = css({
  stack: '$6'
})

export const loadMoreTrigger = css({
  marginTop: '$6'
})
