import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { spacesSelectors } from 'store/spaces'
import { TimeEntry } from 'types/timeEntry'

type DraftEntry = Omit<TimeEntry, 'id' | 'uid' | 'spaceId'>

const initial = {
  dateStart: null,
  dateEnd: null,
  nodes: '[{"text":"","type":"text"}]',
  description: '',
  tags: []
}

export const useDraftEntry = () => {
  const spaceId = useSelector(spacesSelectors.currentSpaceId)

  const [draftEntry, setDraftEntry] = useState<DraftEntry>(initial)

  useEffect(() => {
    reset()
  }, [spaceId])

  const update = (updateData: Partial<DraftEntry>) =>
    setDraftEntry(prev => ({ ...prev, ...updateData }))

  const reset = () => setDraftEntry(initial)

  return { draftEntry, update, reset }
}
