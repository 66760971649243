import { differenceInSeconds } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOdoSelector } from 'store/store'
import { tagsSelectors } from 'store/tags'
import { Tag } from 'types/tag'
import { TimeEntry } from 'types/timeEntry'
import { useRunningEntry } from './useRunningEntry'

const UUID_LENGTH = 36

function getDescription(entry: TimeEntry, tags: Tag[]) {
  const tagIdRegex = new RegExp(`#([\\w-_]{${UUID_LENGTH}})`, 'g')

  let desc = entry.description

  for (const match of desc.matchAll(tagIdRegex)) {
    const tag = tags.find(tag => tag.id === match[1])
    if (!tag) continue
    desc = desc.replace(match[0], '[' + tag.name + ']')
  }

  return desc
}

const getSeconds = (entry: TimeEntry) => {
  return Math.abs(differenceInSeconds(Date.now(), entry.dateStart.valueOf()))
}

export function useTrackHeadTitle() {
  const { t } = useTranslation()
  const [title, setTitle] = useState<string>('Odo')

  const { runningEntry } = useRunningEntry()

  const activeTags = useOdoSelector(tagsSelectors.activeTags)

  useEffect(() => {
    let timeout: { current: NodeJS.Timeout } = {
      current: null
    }

    if (runningEntry) {
      const updateTitle = () => {
        const seconds = getSeconds(runningEntry)
        const time = t('time', { seconds: seconds, showSeconds: true })
        const description = getDescription(runningEntry, activeTags)

        setTitle(`${time} ${description} - Odo`)
        timeout.current = setTimeout(updateTitle, 1000)
      }
      updateTitle()
    } else {
      setTitle('Odo')
    }

    return () => {
      clearTimeout(timeout.current)
    }
  }, [runningEntry, activeTags, t])

  return title
}
